import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { Box, Button } from '@mui/material'
import { EditOutlined, PaperClipOutlined, FileDoneOutlined } from '@ant-design/icons'
import Img from 'gatsby-image'
import EmergencyShareIcon from '@mui/icons-material/EmergencyShare'
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'

function Li({ children }: any) {
    return <li style={{ marginTop: 4 }}>{children}</li>
}

export default function ExplainAppContent() {
    const data = useStaticQuery(graphql`
        query {
            pcReportExImage: file(relativePath: { eq: "computer-report-view.png" }) {
                childImageSharp {
                    fluid(maxWidth: 700) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `)
    return (
        <div
            style={{
                margin: 30,
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
            }}
        >
            <Img
                title="Report Example"
                alt="Report Example"
                className="landing-app-illustration"
                fluid={data.pcReportExImage.childImageSharp.fluid}
            />
            <div style={{ width: 500, marginBottom: 10, marginTop: 30 }}>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 15fr' }}>
                    <div />
                    <h1 style={{ fontSize: 30 }}>Hvad kan ll33a.dk gøre for dig?</h1>
                    <AutoGraphIcon />
                    <span>
                        <p style={{ fontWeight: 'bold' }}>
                            Automatisk beregning af 6-måneders-reglen
                        </p>
                    </span>
                    <AutoGraphIcon />
                    <span>
                        <p style={{ fontWeight: 'bold' }}>
                            Automatisk beregning af 42-dages-reglen inkl. fremtidige planlagte
                            ophold i DK
                        </p>
                    </span>
                    <EmergencyShareIcon />
                    <span>
                        <p style={{ fontWeight: 'bold' }}>
                            Registrer nemt dine opholdssteder i udlandet med Geo-stamp
                        </p>
                    </span>
                    <PaperClipOutlined />
                    <span>
                        <p style={{ fontWeight: 'bold' }}>
                            Arkivsystem i skyen til indsamling og opbevaring af objektiv
                            dokumentation
                        </p>
                    </span>
                    <FileDoneOutlined />
                    <span>
                        <p style={{ fontWeight: 'bold' }}>
                            Auto-generering af compliance rapporter til brug ved indkaldelse til
                            skattekontrol
                        </p>
                    </span>
                    <ConnectWithoutContactIcon />
                    <span>
                        <p style={{ fontWeight: 'bold' }}>
                            Mulighed for online deling af dine data med din rådgiver
                            <br />
                            (F.eks. revisor el. advokat)
                        </p>
                    </span>
                </div>
            </div>
        </div>
    )
}
