import lhpJpeg from '../assets/lhp.jpg'
import React, { useEffect, useState } from 'react'
import { Button, Space } from 'antd'
import { graphql } from 'gatsby'
import Container from '../components/Landing/Container'
import { isMobile } from 'react-device-detect'
import { getStoredAuthToken } from '../utils/auth-token'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Typography,
} from '@mui/material'
import SignupBelt from '../components/Landing/SignupBelt'
import EmpathyContent from '../components/Landing/EmpathyContent'
import ProcessPlanContent from '../components/Landing/ProcessPlanContent'
import ExplainAppContent from '../components/Landing/ExplainAppContent'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

function buildSignupButton(authToken) {
    return (
        <a href={!!authToken ? '/app' : '/signup'}>
            <Button
                className="button-shadow autotest-signup-button"
                shape="round"
                type="primary"
                style={
                    !!authToken
                        ? {}
                        : {
                              borderColor: '#7EAC55',
                              backgroundColor: '#7EAC55',
                          }
                }
            >
                {!!authToken ? 'Login' : 'Prøv gratis helt uforpligtende'}
            </Button>
        </a>
    )
}

function LandingPage({ data }) {
    const [authToken] = useState(getStoredAuthToken())
    const [signupButton, setSignupButton] = useState(buildSignupButton(authToken))

    useEffect(() => {
        setSignupButton(buildSignupButton(authToken))
    }, [authToken])

    return (
        <Container logoImage={data.logoImage} setCookieValue={() => {}} pathName="index">
            <SEO title="Home" />
            <div className="top-landing-container" style={{ paddingTop: isMobile ? 0 : 40 }}>
                <div style={{ width: 500, marginBottom: 100, paddingTop: isMobile ? 0 : 30 }}>
                    <Typography
                        gutterBottom
                        variant="h1"
                        sx={{ fontSize: isMobile ? 20 : 25, fontWeight: 'bold' }}
                    >
                        Gør det enkelt at følge Ligningsloven §33A i hverdagen
                    </Typography>
                    <Space direction="vertical">
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ mr: 1 }}>{signupButton}</Box>
                            {/* {renderBookDemoButton('demo1')} */}
                        </Box>
                        <Typography gutterBottom variant="h6" sx={{ maxWidth: 500 }}>
                            Tag complianceværktøjet i hånden og bliv holdt fri af faldgruberne i
                            lovgivningen
                            <br />- hele vejen til din skattelempelse!
                        </Typography>
                    </Space>
                </div>
                <Img
                    title="App Illustration"
                    alt="App illustration"
                    className="landing-app-illustration"
                    fluid={data.appIllustrationImage.childImageSharp.fluid}
                />
            </div>
            <Divider />
            <Box
                sx={{
                    my: 5,
                    px: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Grid container sx={{ maxWidth: '800px' }} spacing={2}>
                    <Grid item xs={12}>
                        <EmpathyContent />
                    </Grid>
                </Grid>
            </Box>
            <ExplainAppContent />
            <SignupBelt />
            <Box
                sx={{
                    my: 5,
                    px: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Grid container sx={{ maxWidth: '800px' }} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body1" fontSize={20}>
                            ll33a.dk har hjulpet skatteydere, svarende til{' '}
                            <Typography fontSize={20} component="span" fontWeight="bold">
                                mere end 200 skatteår
                            </Typography>
                            , med at få indsigt og kontrol over deres compliance med Ligningsloven
                            §33A
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption">Mød et par af dem her:</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader
                                avatar={<Avatar alt="Lars P" src={lhpJpeg} />}
                                title="Lars Petersen"
                                subheader="Pilot hos Maersk Air Cargo"
                            />
                            <CardContent>
                                <Typography variant="body2">
                                    Jeg har med LL33A app'en fundet en god og solid løsning på 33A.
                                    Nem og overskuelig mht. optælling af dage, samt uploading af
                                    dokumentation. 2019 / 2020 godkendt af Skattestyrelsen
                                    udelukkende med data fra app'en. Tidligere har manuel optælling
                                    af dage / dokumentation været meget tidskrævende, og været en
                                    udfordring i forhold til godkendelse fra Skattestyrelsens side.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader
                                avatar={<Avatar alt="Hussain">H</Avatar>}
                                title="Hussain"
                                subheader="LL§33A skatteyder"
                            />
                            <CardContent>
                                <Typography variant="body2">
                                    Med ll33a.dk har jeg endelig fået styr på min compliance og kan
                                    roligt navigere gennem regler og forskrifter. Deres effektive
                                    værktøj hjalp mig med at rette min lempelsesperiode og erstatte
                                    et fejlbehæftet regneark, hvilket har gjort min
                                    dokumentationsproces mere pålidelig og problemfri. Jeg er også
                                    begejstret for den intuitive platform, der tillader online
                                    planlægning og giver en visuel grafisk fremstilling af
                                    konsekvenserne ved 42-dages-reglen. Jeg bruger deres app dagligt
                                    til nemt at indsamle og organisere min dokumentation. En
                                    fantastisk løsning til en travl erhvervsmand som mig!
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{
                    my: 5,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Grid container sx={{ maxWidth: '800px' }} spacing={2}>
                    <Grid item xs={12}>
                        <ProcessPlanContent />
                    </Grid>
                </Grid>
            </Box>
            <SignupBelt />
            <Box
                sx={{
                    my: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    px: 1,
                    width: 'min(100%, 800px)',
                    m: 'auto',
                }}
            >
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: '24px',
                        foontWeight: 'bold',
                    }}
                >
                    Team op med din rådgiver gennem ll33.dk
                </Typography>

                <Typography
                    variant="body1"
                    sx={{
                        fontSize: '16px',
                    }}
                >
                    Som bruger af ll33a.dk kan du drage fordel af “Rådgiver Platformen”.
                    <br />
                    Den er særligt udviklet til at understøtte rådgivning omkring dine compliance
                    forhold.
                    <br />
                    Her har du mulighed for at give din rådgiver adgang til dine compliance data og
                    derved opnå en række fordele.
                    <br />
                    Har du ikke en rådgiver forvejen, kan du nemt med få klik række ud til vores
                    samarbejdspartnere og få hjælp.
                    <br />
                    <br />I den forbindelse er vi stolte af at kunne præsentere{' '}
                    <Typography component="span" sx={{ textDecoration: 'underline' }}>
                        <a
                            target="_blank"
                            href="https://www.skatteinform.dk/dk/ydelser/udlandsforhold/"
                        >
                            <Typography component="span" sx={{ color: 'red' }}>
                                S
                            </Typography>
                            katte
                            <Typography component="span" sx={{ color: 'red' }}>
                                I
                            </Typography>
                            nform
                        </a>
                    </Typography>
                    , som seneste tilkomne rådgiver på ll33a.dk's Rådgiver Platfom.
                    <br />
                    <a target="_blank" href="https://skatteinform.dk/">
                        SkatteInform
                    </a>{' '}
                    har mange års erfaring i at rådgive personer, med udlandsforhold og står bl.a.
                    bag{' '}
                    <Typography component="span" sx={{ textDecoration: 'underline' }}>
                        <a
                            href="https://taxguide.dk/selvangivelse-af-vaerdipapirer/selvangivelse-af-udenlandsk-indkomst/"
                            target="_blank"
                        >
                            <Typography component="span" sx={{ color: 'red' }}>
                                T
                            </Typography>
                            ax
                            <Typography component="span" sx={{ color: 'red' }}>
                                G
                            </Typography>
                            uide
                        </a>
                    </Typography>
                    .
                </Typography>
                <br />
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography fontWeight="bold">
                            Dine fordele med “Rådgiver Platformen”
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Gennem etableringen af samarbejdsaftaler med anerkendte
                            skatterådgivervirksomheder kan du forvente følgende fordele:
                            <br />
                            <br />
                            <ul>
                                <li>
                                    Tidsbesparelse:
                                    <br />
                                    Rådgiveren kan bruge mindre tid på at indsamle og analysere dine
                                    skatteforhold, da alle nødvendige data præsenteres i vores
                                    compliance-værktøj.
                                </li>
                                <li>
                                    Lavere rådgiversalær:
                                    <br />
                                    Med reduceret tidsforbrug fra rådgiverens side kan du forvente
                                    at opnå besparelser på rådgiversalær.
                                </li>
                                <li>
                                    Højere kvalitetsrådgivning:
                                    <br />
                                    Rådgiveren har bedre overblik over din situation, hvilket giver
                                    mulighed for mere målrettet og kvalificeret skatterådgivning.
                                </li>
                            </ul>
                            <br />
                            Sammenfattende skaber samarbejdet gennem Rådgiver Platformen synergier,
                            der øger effektiviteten, reducerer omkostningerne og forbedrer
                            kvaliteten af den skatterådgivning, du kan forvente at modtage.
                            <br />
                            <Img
                                title="App Illustration"
                                alt="App illustration"
                                className="landing-app-illustration -small"
                                fluid={data.advisorScreenshot.childImageSharp.fluid}
                            />
                            <br />
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: '16px',
                                }}
                            >
                                Under “Overview” af dine compliance rapporter, finder du listen over
                                rådgivere, som du nemt kan indgå aftale med og dele compliance
                                rapporter.
                            </Typography>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <br />
            </Box>
            <Divider sx={{ mb: 1, mt: 2 }} />
            <div
                style={{
                    margin: 30,
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                }}
            >
                <div style={{ width: 500, marginBottom: 100 }}>
                    <h1 style={{ fontSize: 30 }}>Installer appen på din telefon</h1>
                    <p style={{ maxWidth: 400 }}>
                        Gå til menuen på mobil-browseren og tryk tilføj til hjemmeskærm for at
                        downloade appen til din smartphone.
                    </p>
                </div>
                <Img
                    title="Install app"
                    alt="Install app"
                    className="landing-app-illustration"
                    fluid={data.installImage.childImageSharp.fluid}
                />
            </div>
        </Container>
    )
}

export default LandingPage

export const query = graphql`
    query {
        appIllustrationImage: file(relativePath: { eq: "app-illustration.png" }) {
            childImageSharp {
                fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        pcReportExImage: file(relativePath: { eq: "computer-report-view.png" }) {
            childImageSharp {
                fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        calendarMenusImage: file(relativePath: { eq: "calendar-menus.png" }) {
            childImageSharp {
                fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        teamUpImage: file(relativePath: { eq: "team-up.png" }) {
            childImageSharp {
                fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        installImage: file(relativePath: { eq: "install-phone-2.png" }) {
            childImageSharp {
                fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        graphIphoneImage: file(relativePath: { eq: "graph-iphone.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        docExImage: file(relativePath: { eq: "doc-ex.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        blReportImage: file(relativePath: { eq: "bl-report.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        advisorScreenshot: file(relativePath: { eq: "add-advisor-screenshot.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }
`
