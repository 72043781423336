import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default function EmpathyContent() {
    return (
        <div>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography fontWeight="bold">Faldgruber og udfordringer</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        ll33a.dk tager højde for faldgruberne i loven og hjælper dig med
                        udfordringerne i hverdagen.
                        <br />
                        <br />
                        Undgå hyppige årsager* til tab af skattelempelse;
                        <br />
                        <ul>
                            <li>Fejlfortolkninger af 6-måneders-reglen</li>
                            <li>Fejlfortolkninger af 42-dages-reglen</li>
                            <li>Manglende objektiv dokumentation for udlandsophold</li>
                        </ul>
                        <br />
                        <Typography component="span" variant="caption">
                            *Fremgår af afgørelser i Landsskatterettens afgørelsesdatabase
                        </Typography>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography fontWeight="bold">ll33a.dk har hånden på kogepladen</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Udviklet og drevet af skatteydere der selv benytter Ligningsloven §33A.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography fontWeight="bold">Tag kontrollen over din compliance </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Lad app'en styrke din handleevne, kontrol og ejerskab over beslutninger, der
                        påvirker dit levevilkår og hverdag
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
