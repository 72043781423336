import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Link } from 'gatsby'
import { Box, Button } from '@mui/material'

function Li({ children }: any) {
    return <li style={{ marginTop: 4 }}>{children}</li>
}

export default function ProcessPlanContent() {
    return (
        <Box sx={{ background: '#D9D9D9', py: 2 }}>
            <Typography variant="body1" fontWeight="bold" textAlign="center" sx={{ fontSize: 18 }}>
                Sådan kommer du i gang:
            </Typography>
            <Typography variant="body1" sx={{ fontSize: 18 }}>
                <ol type="1">
                    <Li>
                        Tjek at du opfylder de basale kriterier for at benytte Ligningsloven §33A
                        <br />
                        <Link to="/criterias">
                            <Button>se dem her</Button>
                        </Link>
                    </Li>
                    <Li>Download app'en til din mobil med “Gem til hjemmeskærm”</Li>
                    <Li>Start din prøveperiode</Li>
                    <Li>Indsæt data (følg guiden i app'en)</Li>
                    <Li>
                        Nu kan du planlægge ferieophold uden fare for at overtræde reglerne, og nemt
                        dokumentere din compliance når du modtager materialeindkaldelse fra
                        Skattestyrelsen.
                    </Li>
                </ol>
            </Typography>
        </Box>
    )
}
